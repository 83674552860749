/* Estilos que se aplicarán solo cuando se imprima la página */
@media print {
  /* Oculta todo */
  body * {
    visibility: hidden;
  }
  /* Solo muestra el elemento que tenga la clase .print-section */
  .print-section, .print-section * {
    visibility: visible;
  }
  /* Posiciona el elemento .print-section en la página */
  .print-section {
    position: absolute;
    left: 0;
    top: 0;
  }
}
