.text-saigro{
  color:#1982B2;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(35%) sepia(85%) saturate(748%) hue-rotate(158deg) brightness(89%) contrast(88%);
}

/* Estilo para el botón de continuar */
.custom-continue-button {
  background-color: #122C6B !important; /* Bootstrap gray */
  border-color: #122C6B !important;
  color: white !important;
  font-weight: 700 !important;
}

.text-saigro{
  color:#122C6B
}

/* Estilo para el botón del dropdown */
.custom-dropdown-toggle {
  background-color: #122C6B !important; /* Bootstrap gray */
  border-color: #122C6B !important;
  color: white !important;
  font-weight: 700 !important;
}

/* Estilo para el menú del dropdown */
.custom-dropdown-menu {
  background-color: #f8f9fa !important; /* Bootstrap light gray */
  font-weight: 700 !important;
}