.shadow img {
  max-width: 80%; /* Hace que la imagen no sea más ancha que su contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  display: block; /* Elimina espacio extra debajo de la imagen */
  margin: 0 auto; /* Centra la imagen si es más pequeña que el ancho disponible */
}
.logo-img {
  max-width: 80px; /* O el ancho máximo deseado */
  height: auto; /* Mantiene la proporción de la imagen */
  display: block; /* Elimina espacio extra debajo de la imagen */
  margin: 0 auto; /* Centra la imagen si es más pequeña que el ancho disponible */
}

/* Estilo para el botón del dropdown */
.custom-dropdown-toggle {
  background-color: #122C6B !important; /* Bootstrap gray */
  border-color: #122C6B !important;
  color: white !important;
  font-weight: 700 !important;
}

/* Estilo para el menú del dropdown */
.custom-dropdown-menu {
  background-color: #f8f9fa !important; /* Bootstrap light gray */
  font-weight: 700 !important;
}

/* Estilo para los ítems del dropdown */
.custom-dropdown-item {
  color: black !important; /* Para asegurar visibilidad en el fondo claro */
}

.custom-continue-button {
  background-color: #122C6B !important; /* Color principal que mencionaste */
  border-color: #122C6B !important;
  color: white !important;
  font-weight: 700 !important;
  transition: background-color 0.3s ease; /* Transición suave */
}

.custom-continue-button:hover {
  background-color: #1A3B85 !important; /* Color más claro en hover */
  border-color: #1A3B85 !important;
}

.text-saigro{
  color:#122C6B
}